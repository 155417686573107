export default [
  // {
  //   name: 'Services',
  //   link: '/services/',
  // },
  {
    name: 'Case Studies',
    link: '/work/',
  },
  {
    name: 'Clients',
    link: '/clients/',
  },
  {
    name: 'About us',
    link: '/about/',
  },
  {
    name: 'Knowledge hub',
    isDropdown: true,
    isAnchor: true,
    children: [
      {
        name: 'Blog',
        link: '/blog/',
      },
      {
        name: 'Resources',
        link: '/resources/',
      },
      {
        name: 'Agile Product Builders',
        link: '/apb-community-newsletter/',
      },
      {
        name: 'App Costs Calculator',
        link: 'contact',
      },
      {
        name: 'Boldare Boards',
        link: 'https://boards.boldare.com/',
      },
      // @TODO Enable tech-radar
      // {
      //   name: 'Tech Radar',
      //   link: '/tech-radar/',
      // },
    ],
  },
  {
    name: 'Contact us',
    link: '/contact/',
  },
  {
    name: 'Career',
    link: '/career/',
  },
];
