import React from 'react';
import { Section } from 'components';
import { BasicContactForm } from 'components/ContactForm/BasicContactForm';
import { getRecommendClientImageUrl } from '../../utils';
import './BottomForm.scss';

const SECTION_ID = 'bottom-form';

function Form() {
  return (
    <Section
      color={'yellow'}
      id={SECTION_ID}
      fullwidth
      spaceless
      containerClass={'bring-a-qualified-lead__bottom-form'}
    >
      <img
        src={getRecommendClientImageUrl('refferal-3.png')}
        alt={'Workshop - audiance'}
      />

      <div className={'bring-a-qualified-lead__bottom-form__form'}>
        <BasicContactForm />
      </div>
    </Section>
  );
}

export default Form;
