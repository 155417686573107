import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import './ContactDe.scss';
import {
  Banner,
  Cities,
  GermanMedia,
  GermanPartners,
  SuperHeroes,
  WhoAreWe,
  ContactDeAwards,
} from 'views/contactDe/subcomponents';
import { ContactBanner } from 'views/contact/subcomponents';

class ContactDe extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-page single-page--hero connect-de-page'}>
        <Banner />
        <SuperHeroes />
        <ContactBanner
          enableDecorations
          color={'white'}
          sidebarColor={'#F1F1F1'}
          widerDisplay
          dataPdWebforms={
            'https://webforms.pipedrive.com/f/clSStj3tTDomCrDGUQcLwGhAiInLvJMaQWQ9mV8rpzcoPSV2En735LAuhwgYvJ1VQf'
          }
        />
        <Cities />
        <WhoAreWe />
        <GermanMedia />
        <GermanPartners />
        <ContactDeAwards />
      </div>
    );
  }
}

export default ContactDe;
