import React from 'react';

import { FramedAvatar, Section } from 'components';
import { BasicContactForm } from 'components/ContactForm/BasicContactForm';
import { getBookDiscoverySessionImageUrl } from '../../utils';
import SportsFootball from '../../icons/SportsFootball.svg';
import VpnKey from '../../icons/VpnKey.svg';
import './Form.scss';

export const SECTION_ID = 'form';

const LIST = [
  {
    icon: SportsFootball,
    title: 'No strings attached',
  },
  {
    icon: VpnKey,
    title: 'NDA included (upon request)',
  },
];

const Tag = ({ icon, title }) => (
  <div className={'book-discovery-session__form__tag'}>
    <div className={'book-discovery-session__form__tag-icon-wrapper'}>
      <img src={icon} alt={title} />
    </div>
    <div className={'book-discovery-session__form__tag-ribbon'}>
      <p className={'book-discovery-session__form__tag-label'}>{title}</p>
    </div>
  </div>
);

function Form() {
  return (
    <Section
      color={'mine-shaft'}
      id={SECTION_ID}
      containerClass={'book-discovery-session__form'}
    >
      <figure className={'book-discovery-session__form__contact-person'}>
        <FramedAvatar
          src={getBookDiscoverySessionImageUrl('michal-trzpis.png')}
          alt={'Michał Trzpis'}
        />
        <figcaption>
          <p className={'book-discovery-session__form__contact-person__lead'}>
            Your discovery session will be led by
          </p>
          <div
            className={'book-discovery-session__form__contact-person__head'}
          >
            Michał Trzpis
          </div>
          <div
            className={'book-discovery-session__form__contact-person__subhead'}
          >
            Business Solution Architect
            <br />
            business@boldare.com
          </div>
          <p
            className={'book-discovery-session__form__contact-person__details'}
          >
            Michał excels at designing efficient solutions to solve our
            partners’ most complex problems.
          </p>
          {LIST.map(item => (
            <Tag icon={item.icon} title={item.title} />
          ))}
        </figcaption>
      </figure>
      <BasicContactForm />
    </Section>
  );
}

export default Form;
