import React from 'react';
import { Section, Heading, Paragraph } from 'components';
import { getCloudinaryUrl } from 'config/helpers';
import './Contact.scss';
import Bulb from 'assets/images/practitest/bulb_ico.svg';
import { BasicContactForm } from 'components/ContactForm/BasicContactForm';

const Contact = () => {
  return (
    <>
      <div className={'pt__contact-image'}>
        <img
          src={getCloudinaryUrl(
            'assets/practitest/fox-ears.png',
            'f_auto,q_auto',
          )}
          alt={'Fox'}
        />
      </div>
      <Section color={'mine-shaft'} additionalClass={'pt__contact-container'}>
        <div>
          <Heading type={2} size={'large'} color={'white'}>
            Good product design can only be achieved with a 360° view
          </Heading>
          <Paragraph
            additionalClass={'text--light'}
            size={'medium-constant'}
            color={'white'}
          >
            From the first workshop, through each individual wireframe, there
            was a team behind it - designers and developers, working hand in
            hand, produced a well-designed and simply effective web presence.
          </Paragraph>
        </div>
        <div>
          <Section
            id={'form'}
            color={'yellow'}
            additionalClass={'pt__contact-form'}
            spaceless
          >
            <BasicContactForm />
          </Section>
        </div>
        <div className={'pt__contact-bulb pt__hide-mobile'}>
          <img src={Bulb} alt={`Bulb`} loading={'lazy'} />
        </div>
      </Section>
    </>
  );
};

export default Contact;
