import React from 'react';
import { anchorate } from 'anchorate';
import 'intersection-observer';
import { UserGeolocationContextProvider } from 'context';

export const onRouteUpdate = ({ location }) => {
  if (window.piTracker) window.piTracker(location.href);
  anchorate();
};

export const onClientEntry = () => {
  window.mouseflowPath = '/soft-launch';
};

// BWM-137 Pipedrive tracking
export const onInitialClientRender = () => {
  if (typeof window !== 'undefined') {
    const scriptEl = document.createElement('script');
    scriptEl.type = 'text/javascript';
    scriptEl.innerHTML =
      "(function(ss,ex){window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));};(function(d,s){fs=d.getElementsByTagName(s)[0];function ce(src){var cs=d.createElement(s);cs.src=src;cs.async=1;fs.parentNode.insertBefore(cs,fs);};ce('https://sc.lfeeder.com/lftracker_v1_'+ss+(ex?'_'+ex:'')+'.js');})(document,'script');})('JMvZ8gnD3y5a2pOd');";
    document.head.appendChild(scriptEl);
  }
};

export const wrapRootElement = ({ element }) => {
  return (
    <UserGeolocationContextProvider>{element}</UserGeolocationContextProvider>
  );
};
