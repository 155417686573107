import React from 'react';

import { enableAnimations } from 'config/helpers';
import {
  Banner,
  Cities,
  SuperHeroes,
  WhoAreWe,
  ContactNlAwards,
} from 'views/contactNl/subcomponents';
import { ContactBanner } from 'views/contact/subcomponents';
import './ContactNl.scss';

export const ContactNl = () => {
  React.useEffect(() => {
    enableAnimations();
  });

  return (
    <div className={'single-page single-page--hero contact-nl-page'}>
      <Banner />
      <SuperHeroes />
      <ContactBanner
        enableDecorations
        color={'white'}
        sidebarColor={'#F1F1F1'}
        widerDisplay
        dataPdWebforms={
          'https://webforms.pipedrive.com/f/clSStj3tTDomCrDGUQcLwGhAiInLvJMaQWQ9mV8rpzcoPSV2En735LAuhwgYvJ1VQf'
        }
      />
      <Cities />
      <WhoAreWe />
      <ContactNlAwards />
    </div>
  );
};
