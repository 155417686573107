import React from 'react';
import { FramedAvatar, Section } from 'components';
import { BasicContactForm } from 'components/ContactForm/BasicContactForm';
import { getRecommendClientImageUrl } from '../../utils';
import './Form.scss';

export const SECTION_ID = 'form';

function Form() {
  return (
    <Section
      color={'yellow'}
      side={'right'}
      id={SECTION_ID}
      containerClass={'recommend-client__form'}
    >
      <BasicContactForm />
      <figure className={'recommend-client__form__contact-person'}>
        <FramedAvatar
          src={getRecommendClientImageUrl('mpachnik.png')}
          alt={'Mateusz Pachnik'}
        />
        <figcaption>
          <div className={'recommend-client__form__contact-person__head'}>
            Mateusz Pachnik
          </div>
          <div className={'recommend-client__form__contact-person__subhead'}>
            Business Development
            <br />
            mateusz.pachnik@boldare.com
          </div>
          <a
            className={'recommend-client__form__contact-person__book'}
            href={'https://calendly.com/mateusz-pachnik/30min'}
            target={'_blank'}
            rel={'noreferrer'}
          >
            Book a call
          </a>
        </figcaption>
      </figure>
    </Section>
  );
}

export default Form;
