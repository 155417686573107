import PropTypes from 'prop-types';
import React from 'react';

import { Button, Container, Link } from 'components';
import { classNames } from 'utils';
import Arrows from '../../../about/subcomponents/Arrows';

import './CalculatorBanner.scss';

export const CalculatorBanner = ({ withArrows, variant }) => (
  <Container
    additionalClass={'calculator-banner__container'}
  >
    <div className={'calculator-banner'}>
      <p className={'calculator-banner__paragraph calculator-banner__heading'}>Interested in developing an app?</p>
      <p className={'calculator-banner__paragraph calculator-banner__description'}>Use our app development calculator to estimate the costs and timeframe for your app.</p>
      <div className={classNames('calculator-banner__bottom-container', {'calculator-banner__bottom-container--arrows': withArrows})}>
        {withArrows && (
          <div className={'calculator-banner__arrows'}>
            <Arrows />
          </div>
        )}
        <div className={'calculator-banner__link-wrapper'}>
          <Link
            to={'/contact/'}
            id={`ga-${variant}-banner`}
            additionalClass={`ga-cs-calculator-banner`}
          >
            <Button
              color={`${withArrows ? 'yellow' : 'white'}`}
              id={'calculator-banner-button'}
              ariaName={'App cost calculator button'}
              additionalClass={'calculator-banner__button calculator-banner__paragraph--uppercase'}
            >
              Calculate your project
            </Button>
          </Link>
          <p className={'calculator-banner__footnote'}>
            5 short questions in less than 1 minute
          </p>
        </div>
        {withArrows && (
          <div className={'calculator-banner__arrows'}>
          <Arrows />
        </div>
        )}
      </div>
    </div>
  </Container>
);

CalculatorBanner.defaultProps = {
  withArrows: false,
};

CalculatorBanner.propTypes = {
  withArrows: PropTypes.bool,
  variant: PropTypes.string.isRequired,
};
