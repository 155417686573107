import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import PropTypes from 'prop-types';
import { ContactBanner, ContactOffices } from './subcomponents';
import './Connect.scss';

class Contact extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    const { dataPdWebforms } = this.props;

    return (
      <div className={'single-page single-page--hero connect-page'}>
        <ContactBanner
          enableDecorations
          color={'white'}
          sidebarColor={'#F1F1F1'}
          widerDisplay
          dataPdWebforms={dataPdWebforms}
        />
        <ContactOffices />
      </div>
    );
  }
}

Contact.propTypes = {
  dataPdWebforms: PropTypes.string,
};

Contact.defaultProps = {
  dataPdWebforms:
    'https://webforms.pipedrive.com/f/clSStj3tTDomCrDGUQcLwGhAiInLvJMaQWQ9mV8rpzcoPSV2En735LAuhwgYvJ1VQf',
};

export default Contact;
