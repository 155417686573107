import PropTypes from 'prop-types';
import React from 'react';

import { Button, Link } from 'components';

import './CalculatorBox.scss';

export const CalculatorBox = ({ theme, variant }) => (
  <div
    className={`calculator-box calculator-box--${theme}`}
  >
    <p className={'calculator-box__paragraph calculator-box__heading'}>Interested in developing an app?</p>
    <p className={'calculator-box__paragraph calculator-box__description'}>Use our app development calculator to estimate the costs and timeframe for your app.</p>
    <Link
      to={'/contact/'}
      additionalClass={'calculator-box__link ga-cs-calculator-box'}
      id={`ga-${variant}-box`}
    >
      <Button
        color={`${theme === 'white' ? 'yellow' : 'white'}`}
        id={'calculator-box-button'}
        ariaName={'App cost calculator button'}
        additionalClass={'calculator-box__button calculator-box__paragraph--uppercase'}
      >
        Calculate your project
      </Button>
    </Link>
    <p className={'calculator-box__footnote'}>
      5 short questions in less than 1 minute
    </p>
  </div>
);

CalculatorBox.propTypes = {
  theme: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
};
