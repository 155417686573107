import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import './BasicContactForm.scss';

export const BasicContactForm = ({ dataPdWebforms }) => {
  const [isWindowReady, setIsWindowReady] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsWindowReady(true);
    }
  }, []);

  if (!isWindowReady) {
    return (
      <div className={'basic-contact-form'}>
        <span className={'basic-contact-form__loader'} />
      </div>
    );
  }

  return (
    <div className={'pipedriveWebForms'} data-pd-webforms={dataPdWebforms}>
      <script src={'https://webforms.pipedrive.com/f/loader'} />
    </div>
  );
};

BasicContactForm.defaultProps = {
  dataPdWebforms:
    'https://webforms.pipedrive.com/f/clSStj3tTDomCrDGUQcLwGhAiInLvJMaQWQ9mV8rpzcoPSV2En735LAuhwgYvJ1VQf',
};

BasicContactForm.propTypes = {
  dataPdWebforms: PropTypes.string,
};
