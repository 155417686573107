import React from 'react';
import AppCostsCalculator from 'views/appCostsCalculator';
import Layout from 'components/layout';
import { NavBar } from 'components';
import { pages } from 'config/pages';
import config from '../../../meta/config';

const appCostPage = pages.resources.items['app-costs-calculator'];
const { navColors } = appCostPage;
const url = `${config.siteUrl}/contact/`;
const canonical = `${url}`;

const AppCostsCalculatorPage = () => {
  const page = {
    ...appCostPage,
  };

  return (
    <Layout {...{ page, canonical }}>
      <NavBar color={navColors} />
      <AppCostsCalculator />
    </Layout>
  );
};

export default AppCostsCalculatorPage;
