import React, { Component } from 'react';
import { enableAnimations } from 'config/helpers';
import './DigitaleTransformationDe.scss';
import {
  Banner,
  About,
  Webinar,
  CaseStudies,
  Testimonials,
  Kontakt,
  Quality,
} from 'views/digitaleTransformationDe/subcomponents';
import { ContactBanner } from 'views/contact/subcomponents';

class DigitaleTransformationDe extends Component {
  componentDidMount() {
    enableAnimations();
  }

  render() {
    return (
      <div className={'single-page single-page--hero connect-de-page'}>
        <Banner />
        <About />
        <Webinar />
        <CaseStudies />
        <Testimonials />
        <Kontakt />
        <Quality />
        <ContactBanner
          enableDecorations
          color={'white'}
          sidebarColor={'#F1F1F1'}
          widerDisplay
          dataPdWebforms={
            'https://webforms.pipedrive.com/f/clSStj3tTDomCrDGUQcLwGhAiInLvJMaQWQ9mV8rpzcoPSV2En735LAuhwgYvJ1VQf'
          }
        />
      </div>
    );
  }
}

export default DigitaleTransformationDe;
