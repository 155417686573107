import React from 'react';
import PropTypes from 'prop-types';
import { buttonConfigShape } from 'components/TopBanner/new';
import { AnnouncementBannerSpace } from 'components/AnnouncementBanner';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

const Menu = ({ bannerConfig, closeBanner }) => (
  <>
    {!!bannerConfig && <AnnouncementBannerSpace />}
    <DesktopMenu bannerConfig={bannerConfig} closeBanner={closeBanner} />
    <MobileMenu bannerConfig={bannerConfig} closeBanner={closeBanner} />
  </>
);

Menu.defaultProps = {
  bannerConfig: undefined,
  closeBanner: () => {},
};

Menu.propTypes = {
  bannerConfig: PropTypes.shape({
    button: buttonConfigShape,
    display: PropTypes.bool,
    bg: PropTypes.string,
    description: PropTypes.shape({
      desktop: PropTypes.string,
      mobile: PropTypes.string,
      color: PropTypes.string,
    }),
  }),
  closeBanner: PropTypes.func,
};

export default Menu;
