import React from 'react';
import PropTypes from 'prop-types';

import { classNames } from 'utils';
import { getCloudinaryUrl } from 'config/helpers';
import {
  Link,
  MainTag,
  CoverImage,
  Heading,
  Description,
  Author,
} from 'components/WorkBoxes/subcomponents';

import './Article.scss';

const Article = ({
  type,
  isVariantB,
  link,
  authors,
  post: {
    node: {
      frontmatter: { title, box, author, cover },
    },
  },
}) => {
  const coverImage = box.content.coverImage || cover || 'img/human-eye.jpg';
  const authorData = authors.filter(
    edge => edge.node.frontmatter.title === author,
  );

  return (
    <div className={'work-article-box'}>
      <div className={'work-article-box__container'}>
        <Link href={link} />
        <MainTag type={type} />
        <CoverImage
          alt={`${title} - ${type} cover`}
          image={getCloudinaryUrl(coverImage, 'f_auto,q_auto,h_320')}
          shadow
        />
        <div
          className={classNames('work-article-box__content', {
            'work-article-box__content--no-text': isVariantB,
          })}
        >
          <Heading text={title} isVariantB={isVariantB} />
          {!isVariantB && <Description text={box.content.tileDescription} />}
          <Author authorData={authorData} />
        </div>
      </div>
    </div>
  );
};

Article.defaultProps = {
  isVariantB: false,
};

Article.propTypes = {
  type: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  authors: PropTypes.array.isRequired,
  post: PropTypes.object.isRequired,
  isVariantB: PropTypes.bool,
};

export default Article;
