import React from 'react';
import { Section, Heading, Paragraph } from 'components';
import { Col, Row } from 'react-flexbox-grid';
import PropTypes from 'prop-types';
import { getCloudinaryUrl } from 'config/helpers';
import './Contact.scss';
import { BasicContactForm } from 'components/ContactForm/BasicContactForm';

const Contact = React.forwardRef(({ widerDisplay }, ref) => (
  <Section
    additionalClass={'contact-banner'}
    color={'yellow'}
    spaceless
    fullwidth
    id={'contact'}
  >
    <Row className={'no-gap contact-banner__row'}>
      <Col md={9} lg={8} xl={8}>
        <>
          <div
            ref={ref}
            className={`${
              widerDisplay
                ? 'contact-banner__content--wider'
                : 'contact-banner__content'
            }`}
            style={{ backgroundColor: '#f2da3a' }}
          >
            <BasicContactForm
              dataPdWebforms={
                'https://webforms.pipedrive.com/f/6GW0AQgTrmdoUUlDUARoEdxmhIUFDFyUZOw9KfVHRySNcfCTJmMmphOZfU5bEsBMVd'
              }
            />
          </div>
        </>
      </Col>
      <Col md={3} lg={4} xl={4} className={'contact-page__sidebar'}>
        <div className={'discoverai__sidebar-photo'}>
          <img
            src={getCloudinaryUrl('img/Olivier.png', 'f_auto,q_auto,w_auto')}
            alt={'business-consultant'}
          />
          <div className={'discoverai__sidebar-photo-person-information'}>
            <Heading color={'white'} size={'medium-plus-small'}>
              Olivier Halupczok
            </Heading>
            <Paragraph color={'white'} size={'medium-plus-small'}>
              Generative AI Engineer
            </Paragraph>
          </div>
        </div>
      </Col>
    </Row>
  </Section>
));

Contact.propTypes = {
  enableDecorations: PropTypes.bool,
  color: PropTypes.string,
  sidebarColor: PropTypes.string,
  widerDisplay: PropTypes.bool,
};

Contact.defaultProps = {
  enableDecorations: true,
  color: 'white',
  sidebarColor: 'seashell',
  widerDisplay: true,
};

export default Contact;
