import React from 'react';
import PropTypes from 'prop-types';

import { Section } from 'components';
import { BasicContactForm } from 'components/ContactForm/BasicContactForm';
import './ContactUsForm.scss';

const ContactUsForm = ({ id, dataPdWebforms }) => {
  return (
    <Section
      id={id}
      color={'yellow'}
      additionalClass={'contact-us-form'}
      spaceless
    >
      <BasicContactForm dataPdWebforms={dataPdWebforms} />
    </Section>
  );
};

ContactUsForm.propTypes = {
  id: PropTypes.string.isRequired,
  dataPdWebforms: PropTypes.string.isRequired,
};

export default ContactUsForm;
